import React, { useState } from 'react'
import { Collapse, Navbar, NavbarToggler } from 'reactstrap'
import TopNavRightSideNavItem from './topnavrightsidenavitem'
import LogoText from './logo-text'

const NavbarTop = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar light expand="md" className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit">
        <LogoText id="topLogo" />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <TopNavRightSideNavItem />
        </Collapse>
      </Navbar>
    </div>
  )
}

export default NavbarTop
