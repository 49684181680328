import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap'
import Avatar from './avatar'
import { Link } from 'gatsby'

const TopNavRightSideNavItem = () => {
  const [permission, setPermission] = useState(null)
  const { logout } = useAuth0()
  const windowGlobal = typeof window !== 'undefined' && window
  const localStorageVar = windowGlobal ? localStorage : null
  const storename = localStorageVar ? localStorageVar.getItem('name') : 'person'
  const storepicture = localStorageVar ? localStorageVar.getItem('picture') : 'icon'
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [myProfile, setMyprofile] = useState(null)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleAdminDropdown = () => setAdminDropdownOpen(prevState => !prevState);

  useEffect(() => {
    let getMenu = JSON.parse(localStorageVar.getItem('menu'))
    if (getMenu != null) {
      setPermission(getMenu.filter(permission => permission != 'my-profile'))
      setMyprofile(getMenu.filter(permission => permission === 'my-profile'))
    }
  }, [])

  return (
    <>
      <Nav navbar className="navbar-nav-icons ml-auto align-items-center">
      {permission
          ? permission.map((data, index) => {
              if (data === 'admin') {
                return (
                  <Dropdown
                    nav
                    inNavbar
                    isOpen={adminDropdownOpen}
                    toggle={toggleAdminDropdown}
                    key={index}
                    onMouseOver={() => setAdminDropdownOpen(true)}
                    onMouseLeave={() => setAdminDropdownOpen(false)}
                  >
                    <DropdownToggle nav onClick={e => e.preventDefault()} className="pr-0">
                      Admin
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-card">
                      <div className="bg-white rounded-soft py-2">
                        <DropdownItem tag={Link} to="/admin/wineries">Wineries</DropdownItem>
                        <DropdownItem tag={Link} to="/admin/users">Users</DropdownItem>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                );
              } else {
                return (
                  <NavItem key={index}>
                    <NavLink id={`menuItem_${data}`} className="nav-link" tag={Link} to={'/' + data}>
                      {data}
                    </NavLink>
                  </NavItem>
                );
              }
            })
          : ''}

        <Dropdown
          id="authDropdown"
          nav
          inNavbar
          isOpen={dropdownOpen}
          toggle={toggle}
          onMouseOver={() => {
            let windowWidth = window.innerWidth
            windowWidth > 992 && setDropdownOpen(true)
          }}
          onMouseLeave={() => {
            let windowWidth = window.innerWidth
            windowWidth > 992 && setDropdownOpen(false)
          }}
        >
          {storepicture ? (
            <>
              <DropdownToggle nav className="pr-0">
                <Avatar src={storepicture} />
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu-card">
                <div className="bg-white rounded-soft py-2">
                  <DropdownItem>Welcome {storename}</DropdownItem>
                  {myProfile ? (
                    <DropdownItem tag={Link} to={'/' + myProfile} style={{display:"none"}}>
                      {myProfile}
                    </DropdownItem>
                  ) : null}
                  <DropdownItem
                    id="logoutButton"
                    onClick={() => {
                      logout({ returnTo: window.location.origin })
                      localStorageVar.clear()
                    }}
                  >
                    Logout
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </>
          ) : (
            ''
          )}
        </Dropdown>
      </Nav>
    </>
  )
}

export default TopNavRightSideNavItem
