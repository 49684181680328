import React from 'react'
import BlackLogo from './black-logo'
import VdLogo from './vd-logo'

const LogoText = () => {
  return (
    <div className="d-flex blackLogo">
      <BlackLogo width={150} className="blackLogoDisplay" />
      <VdLogo width={45} className="VdLogo" />
      <h1 className="logo-header">Winery Portal</h1>
    </div>
  )
}

export default LogoText
