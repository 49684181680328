import React from 'react'
import NavbarTop from '../components/navbartop'
import Footer from '../components/footer'

const DashboardLayout = ({ children }) => {
  return (
    <div>
      <div className="container">
        <div className="content">
          <NavbarTop />
          {children}
        </div>
      </div>
      <footer className="footerDashboard">
        <Footer />
      </footer>
    </div>
  )
}

export default DashboardLayout
